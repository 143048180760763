<template>
  <el-card id="productData">
    <main>
      <el-table
        :data="tableData"
        border
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <!-- <el-table-column
          prop="companyId"
          label="公司ID"
          align="center"
        ></el-table-column> -->
        <el-table-column
          prop="createUserId"
          label="创建人ID"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="createUser"
          label="创建人姓名"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="productCoding"
          label="产品编码"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="productName"
          label="产品名字"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="procuctPrice"
          label="产品价格"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="unit"
          label="产品单位"
          align="center"
        ></el-table-column>
        <el-table-column prop="status" label="状态" align="center">
          <template #default="scope">
            <span v-if="scope.row.status == 1">上架</span>
            <span v-if="scope.row.status == 2">下架</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="productDescribe"
          label="产品描述"
          align="center"
        ></el-table-column>
        <el-table-column prop="createDate" label="创建时间" align="center">
          <template v-slot="scope">
            <div>
              {{ getYMDHMS("YYYY-mm-dd HH:MM:SS", scope.row.createDate) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="updatea" label="更新时间" align="center">
          <template v-slot="scope">
            <div>
              {{ getYMDHMS("YYYY-mm-dd HH:MM:SS", scope.row.updatea) }}
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="footer">
        <!-- 操作按钮 -->
        <div class="btns">
          <el-button type="primary" @click="add" size="small">添加</el-button>
        </div>
        <!--分页  -->
        <div class="pagination">
          <el-pagination
            background
            layout="total, prev, pager, next,sizes"
            :current-page="pageIndex"
            :page-size="pageSize"
            :page-sizes="[10, 20, 50, 100, 200]"
            :total="pageTotal"
            @size-change="handleSizeChange"
            @current-change="handlePageChange"
          ></el-pagination>
        </div>
      </div>
    </main>
  </el-card>
</template>

<script>
import { ElMessage } from "@/assets/js/elementui-api";
let Message = new ElMessage();
import { productSelect } from "@/api/product";
export default {
  data() {
    return {
      numsArray: [], //选中号码的ID
      multipleSelection: [],
      delVisible: false,
      updateVisible: false,
      addProductVisible: false,
      form: {},
      tableData: [], //表格内绑定的数据
      pageTotal: 0, //页数
      pageIndex: 1, //当前在第几页
      pageSize: 10, //前端规定每页显示的数量
      page: 1, //第几页
      size: 10, //每页的数量
    };
  },
  created() {
    this.getList();
  },
  methods: {
    // 添加
    add() {
      this.multipleSelection = this.multipleSelection.map((v) => {
        // v.sellingPrice = 0; // 售价
        // v.discountPrice = 1; // 折扣
        // v.num = 0; // 数量
        // v.sumMoney = 0; // 合计
        return v;
      });
      let obj = {
        multipleSelection: this.multipleSelection,
        bool: false,
      };
      console.log("子组件multipleSelection", this.multipleSelection);
      this.$emit("productTableData", obj); // 触发父组件的方法，并传递参数index
    },
    //选择table表格
    handleSelectionChange(val) {
      this.multipleSelection = val; // 选择的列表
      console.log("multipleSelection", val);
      this.numsArray = [];
      val.forEach((item) => {
        this.numsArray.push(item.id);
      });
      console.log(this.numsArray);
    },
    // 展示
    getList() {
      let params = {
        page: this.page,
        size: this.size,
      };
      let res = productSelect(params);
      res.then((res) => {
        this.tableData = res.data.data;
        this.pageTotal = res.data.total;
      });
    },
    // 分页
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    // 转换日期
    getYMDHMS(format, date) {
      if (!date) {
        return "";
      }
      date = new Date(date);
      const dataItem = {
        "Y+": date.getFullYear().toString(),
        "m+": (date.getMonth() + 1).toString(),
        "d+": date.getDate().toString(),
        "H+": date.getHours().toString(),
        "M+": date.getMinutes().toString(),
        "S+": date.getSeconds().toString(),
      };
      Object.keys(dataItem).forEach((item) => {
        const ret = new RegExp(`(${item})`).exec(format);
        if (ret) {
          format = format.replace(
            ret[1],
            ret[1].length === 1
              ? dataItem[item]
              : dataItem[item].padStart(ret[1].length, "0")
          );
        }
      });
      return format;
    },
  },
};
</script>

<style lang="less" scoped>
#productData {
  main {
    .footer {
      display: flex;
      justify-content: space-between;
      margin: 20px 0;
      .pagination {
        text-align: right;
      }
    }
  }
}
</style>