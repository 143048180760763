<template>
  <el-card id="clientsCharts">
    <header>
      <!-- <h1>新增客户详情</h1> -->
      <div class="search">
        <div class="left">
          <div class="left-wrap" v-if="roleName == '企业'">
            <span>部门</span>
            <el-select
              v-model="departId"
              placeholder="请选择部门"
              size="small"
              filterable
            >
              <el-option
                v-for="item in underDepart"
                :key="item.id"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div
            class="left-wrap"
            v-if="roleName == '组长' || roleName == '企业'"
          >
            <span>员工</span>
            <el-select
              v-model="userId"
              placeholder="请选择员工"
              size="small"
              filterable
            >
              <el-option
                v-for="item in underUser"
                :key="item.id"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="right">
          <!-- <el-button @click="render('')" type="primary" size="small"
            >今日</el-button
          >
          <el-button
            @click="render('/weekstatusAll')"
            type="primary"
            size="small"
            >本周</el-button
          >
          <el-button
            @click="render('/monthstatusAll')"
            type="primary"
            size="small"
            >当月</el-button
          > -->
          <el-date-picker
            v-model="monthTime"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            size="small"
          >
          </el-date-picker>
          <el-button
            style="margin-left: 30px"
            type="primary"
            icon="el-icon-search"
            @click="searchBtn"
            size="small"
            class="searchBtn"
            >查找</el-button
          >
          <el-button @click="reset()" type="primary" size="small"
            >重置</el-button
          >
        </div>
      </div>
    </header>
    <el-divider></el-divider>
    <main>
      <div class="content">
        <div class="client-type" v-for="v in infoData" :key="v.id">
          <span> {{ v.value }}</span>
          <p>{{ v.name }}</p>
        </div>
      </div>
      <div id="chartPie" style="width: 600px; height: 400px"></div>
    </main>
  </el-card>
</template>

<script>
import * as echarts from "echarts";
import { phoneFindUser, phoneTimeAll, phoneCountAddStatus } from "@/api/phone";
export default {
  data() {
    return {
      monthTime: [],
      infoData: [],
      chartPie: null,
      departId: null,
      userId: null,
      underDepart: [], // 部门
      underUser: [], // 员工
      roleName: null,
    };
  },
  async created() {
    // this.render("/daystatusAll");
    this.getList();
    this.roleName = localStorage.getItem("roleName");
    this.id = localStorage.getItem("id");
    if (this.roleName == "管理员" || this.roleName == "企业") {
      this.getAllUser();
    }
  },
  methods: {
    // 获取所有部门和员工
    getAllUser() {
      let res = phoneFindUser();
      return res.then((res) => {
        let data = res.data.data;
        console.log("处理数据", data);
        // 处理数据
        // 部门
        this.underDepart = data.depart.map((v, i) => {
          console.log("部门每项", v);
          return { id: i + 1, name: v.departName, value: v.id };
        });
        // 人员
        this.underUser = data.user.map((v, i) => {
          return { id: i + 1, name: v.realname, value: v.id };
        });
        console.log("所有用户信息", res.data);
        return res.data;
      });
      // departId
      // userId
      // depart_name 部门名字
    },

    drawPieChart() {
      this.chartPie = echarts.init(document.getElementById("chartPie"));
      this.chartPie.setOption({
        title: {
          x: "center",
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        legend: {
          orient: "vertical",
          left: "left",
          data: [
            "成交客户",
            "意向客户",
            "一般客户",
            "无意向客户",
            "黑名单",
            "无状态",
            "未接通",
          ],
        },
        series: [
          {
            name: "访问来源",
            type: "pie",
            radius: "55%",
            center: ["50%", "50%"],
            data: this.infoData,
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      });
    },
    drawCharts() {
      this.drawPieChart();
    },
    // 展示
    getList() {
      if (this.monthTime) {
        this.sta = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.monthTime[0]);
        this.end = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.monthTime[1]);
      }
      let params = {
        departId: this.departId,
        userId: this.userId,
        sta: this.sta,
        end: this.end,
      };
      let res = phoneCountAddStatus(params);
      res.then((res) => {
        let data = [
          "一般客户",
          "意向客户",
          "无意向客户",
          "成交客户",
          "黑名单",
          "无状态",
          "未接通",
        ];
        let arr = [];
        for (let i = 0; i <= 6; i++) {
          arr.push({ id: i, name: data[i], value: res.data.data[i] || 0 });
        }
        this.infoData = arr;
        let echars = this.drawCharts();
      });
    },
    // 搜索
    searchBtn() {
      this.getList();
    },
    // 重置
    reset() {
      this.userId = null;
      this.departId = null;
      // this.render("/daystatusAll");
      this.monthTime = null;
      this.sta = null;
      this.end = null;
      this.getList();
    },
    // 转换日期
    getYMDHMS(format, date) {
      // 使用方法
      // dateChangeFormat('YYYY-mm-dd HH:MM:SS', "2021-07-02T14:19:59+08:00")
      if (!date) {
        return "";
      }
      date = new Date(date);
      const dataItem = {
        "Y+": date.getFullYear().toString(),
        "m+": (date.getMonth() + 1).toString(),
        "d+": date.getDate().toString(),
        "H+": date.getHours().toString(),
        "M+": date.getMinutes().toString(),
        "S+": date.getSeconds().toString(),
      };
      Object.keys(dataItem).forEach((item) => {
        const ret = new RegExp(`(${item})`).exec(format);
        if (ret) {
          format = format.replace(
            ret[1],
            ret[1].length === 1
              ? dataItem[item]
              : dataItem[item].padStart(ret[1].length, "0")
          );
        }
      });
      return format;
    },
  },
  updated: function () {
    this.drawCharts();
  },
};
</script>

<style lang="less" scoped>
#clientsCharts {
  header {
    .search {
      display: flex;
      // margin-top: 20px;
      // flex-direction: column;
      align-items: center;
      .left {
        display: flex;
        align-items: center;
        height: 100%;
        flex-wrap: wrap;
        line-height: 30px;
        .left-wrap {
          display: flex;
          align-items: center;
          span {
            margin: 10px;
            width: 30%;
            text-align: right;
            align-items: center;
            font-size: 12px;
          }
        }
      }
      .right {
        // margin-top: 20px;
        text-align: right;
        display: flex;
        /* justify-content: space-between; */
        justify-content: flex-end;
        align-items: center;
        height: 100%;
      }
    }
  }
  main {
    display: flex;
    justify-content: space-between;
    .content {
      width: 50%;
      display: flex;
      // justify-content: space-between;
      flex-wrap: wrap;
      padding: 30px;
      box-sizing: border-box;
      text-align: center;
      .client-type {
        text-align: center;
        width: 158px;
        padding: 20px 0;
        box-sizing: border-box;
      }
      .client-type > span {
        font-size: 32px;
      }

      .client-type > p {
        font-size: 18px;
      }
    }
  }
}
</style>