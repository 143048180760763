/**
 * user-sms
 * 短信
 */

import request from '@/utils/request'

// 获取数据
export const userSmsList = (data) => {
    return request({
        method: 'post',
        url: '/user-sms/list',
        data
    })
}
// 添加短信
export const userSmsAdd = (data) => {
    return request({
        method: 'post',
        url: '/user-sms/add',
        data
    })
}
// 编辑短信
export const userSmsUpdate = (data) => {
    return request({
        method: 'post',
        url: '/user-sms/update',
        data
    })
}
// 查询是否开通短信服务
export const userSmsFindOne = (data) => {
    return request({
        method: 'post',
        url: '/user-sms/findOne',
        data
    })
}
// 
export const userSmsUpdateUserStatus = (data) => {
    return request({
        method: 'post',
        url: '/user-sms/update-user-status',
        data
    })
}