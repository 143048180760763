/**
 * product
 * 产品
 */

import request from '@/utils/request'

// 新增产品
export const productAdd = (data) => {
    return request({
        method: 'post',
        url: '/product/add',
        data
    })
}
// 删除产品
export const productDelete = (data) => {
    return request({
        method: 'post',
        url: '/product/delete',
        data
    })
}
// 修改产品
export const productUpdate = (data) => {
    return request({
        method: 'post',
        url: '/product/update',
        data
    })
}
// 查询产品
export const productSelect = (data) => {
    return request({
        method: 'post',
        url: '/product/select',
        data
    })
}