<template>
  <el-card id="clientNums">
    <el-card>
      <header>
        <h1>客户列表</h1>
        <el-card id="clientNumber">
          <div style="display: flex; justify-content: space-between">
            <!-- <div v-for="v in numberList" :key="v.id" class="num-wrap"> -->
            <div
              class="num-wrap"
              style="background-color: #409eff; border-radius: 5px"
            >
              <div class="number">{{ ybkh_status }}</div>
              <div class="title">一般客户</div>
            </div>
            <div
              class="num-wrap"
              style="background-color: #409eff; border-radius: 5px"
            >
              <div class="number">{{ yxkh_status }}</div>
              <div class="title">意向客户</div>
            </div>
            <div
              class="num-wrap"
              style="background-color: #409eff; border-radius: 5px"
            >
              <div class="number">{{ wyxkh_status }}</div>
              <div class="title">无意向客户</div>
            </div>
            <div
              class="num-wrap"
              style="background-color: #409eff; border-radius: 5px"
            >
              <div class="number">{{ cjkh_status }}</div>
              <div class="title">成交客户</div>
            </div>
            <div
              class="num-wrap"
              style="background-color: #409eff; border-radius: 5px"
            >
              <div class="number">{{ hmd_status }}</div>
              <div class="title">黑名单</div>
            </div>
            <!-- </div> -->
          </div>
        </el-card>
        <div class="search">
          <div class="left">
            <div class="left-wrap">
              <span>号码</span>
              <el-input
                v-model="activePhone"
                placeholder="请输入号码"
                size="small"
              ></el-input>
            </div>
            <div class="left-wrap">
              <span>企业</span>
              <el-input
                v-model="company"
                placeholder="请输入企业名称"
                size="small"
              ></el-input>
            </div>
            <div class="left-wrap" v-if="realname == 'admin'">
              <span>企业</span>
              <el-input
                v-model="company"
                placeholder="请输入公司名称"
                filterable
                size="small"
              ></el-input>
            </div>
            <div class="left-wrap">
              <span>行业</span>
              <el-input
                v-model="industry"
                placeholder="请输入行业"
                size="small"
              ></el-input>
            </div>
            <div class="left-wrap">
              <span>客户来源</span>
              <el-input
                v-model="customerSource"
                placeholder="请输入客户来源"
                size="small"
              ></el-input>
            </div>
            <div class="left-wrap">
              <span>备注</span>
              <el-input
                v-model="remarkSearch"
                placeholder="请输入备注"
                size="small"
              ></el-input>
            </div>
            <div class="left-wrap" v-if="roleName == '组长'">
              <span>员工</span>
              <el-select
                v-model="id1"
                placeholder="请选择员工"
                filterable
                size="small"
              >
                <el-option
                  v-for="item in underUser"
                  :key="item.id"
                  :label="item.realname"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
            <div
              class="left-wrap"
              v-if="roleName == '管理员' || roleName == '企业'"
            >
              <span>部门</span>
              <el-select
                v-model="departId"
                placeholder="请选择部门"
                filterable
                size="small"
              >
                <el-option
                  v-for="item in underDepart"
                  :key="item.id"
                  :label="item.departName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
            <div
              class="left-wrap"
              v-if="roleName == '管理员' || roleName == '企业'"
            >
              <span>员工</span>
              <el-select
                v-model="userId"
                placeholder="请选择员工"
                filterable
                size="small"
              >
                <el-option
                  v-for="item in underUser"
                  :key="item.id"
                  :label="item.realname"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
            <div class="left-wrap">
              <span>导入时间</span>
              <el-date-picker
                v-model="createTime"
                type="datetimerange"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                align="center"
                size="small"
              >
              </el-date-picker>
            </div>
            <div class="left-wrap">
              <span>修改时间</span>
              <el-date-picker
                v-model="changeTime"
                type="datetimerange"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                align="center"
                size="small"
              >
              </el-date-picker>
            </div>
            <div class="left-wrap">
              <span>分配时间</span>
              <el-date-picker
                v-model="getTime"
                type="datetimerange"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                align="center"
                size="small"
              >
              </el-date-picker>
            </div>
          </div>
          <div class="right">
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="handleSearch"
              size="small"
              class="searchBtn"
              >查找
            </el-button>
            <el-button
              class="reset"
              type="primary"
              icon="el-icon-refresh"
              @click="reset()"
              size="small"
              >重置
            </el-button>
            <el-button
              type="primary"
              @click="distributeNums()"
              size="small"
              class="distributeNums"
              v-if="roleName != '人员'"
              >分配号码
            </el-button>
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="allHandleSearch"
              size="small"
              class="searchBtn"
              >全局查找
            </el-button>
            <el-button
              type="primary"
              icon="el-icon-folder-opened"
              @click="allNums()"
              size="small"
              class="allNums"
              >批量导入号码
            </el-button>
            <el-button
              type="primary"
              icon="el-icon-folder-opened"
              @click="addNums()"
              size="small"
              class="addNums"
              >添加号码
            </el-button>
            <el-button
              type="danger"
              @click="moreDelete()"
              class="delete"
              size="small"
              icon="el-icon-delete"
              >批量释放
            </el-button>
          </div>
        </div>
      </header>
      <el-divider></el-divider>
      <main>
        <el-tabs v-model="activeData" @tab-click="clickData">
          <el-tab-pane label="一般客户" name="ybkh">
            <el-table
              :data="tableData"
              border
              class="table"
              ref="multipleTable"
              header-cell-class-name="table-header"
              @selection-change="handleSelectionChange"
              @row-dblclick="dblclick"
            >
              <el-table-column
                type="selection"
                width="55"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="userRealname"
                label="归属人"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="activePhone"
                label="手机号码"
                align="center"
                v-if="(!hideNum && id != 5051) || roleName == '企业'"
              ></el-table-column>
              <el-table-column
                prop="phone"
                label="手机号码"
                align="center"
                v-if="(hideNum || id == 5051) && roleName != '企业'"
              ></el-table-column>
              <el-table-column
                prop="company"
                label="企业名称"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="customerPhone"
                label="姓名"
                align="center"
              ></el-table-column>

              <el-table-column
                prop="industry"
                label="行业"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="address"
                label="地址"
                align="center"
              ></el-table-column>
              <el-table-column prop="status" label="状态" align="center">
                <template #default="scope">
                  <span v-if="scope.row.status == 0">一般客户</span>
                  <span v-if="scope.row.status == 1">意向客户</span>
                  <span v-if="scope.row.status == 2">无意向客户</span>
                  <span v-if="scope.row.status == 3">成交客户</span>
                  <span v-if="scope.row.status == 4">黑名单</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="createTime"
                label="导入时间"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="changeTime"
                label="修改时间"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="remarks"
                label="备注"
                align="center"
                class="hide"
              ></el-table-column>
              <el-table-column
                prop="choosePeople"
                label="决策人"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="customerScale"
                label="公司规模"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="customerSource"
                label="客户来源"
                align="center"
              ></el-table-column>
              <el-table-column
                label="操作"
                width="280"
                align="center"
                fixed="right"
              >
                <template #default="scope">
                  <el-button
                    type="success"
                    @click="call(scope)"
                    v-if="roleName != '企业'"
                    size="small"
                    >拨打
                  </el-button>
                  <el-button
                    type="primary"
                    @click="addBtn(scope)"
                    v-if="roleName != '企业'"
                    size="small"
                    >短信提醒跟进
                  </el-button>
                  <!-- 释放号码 -->
                  <el-button
                    type="danger"
                    @click="removeButton(scope.row, scope.$index)"
                    size="small"
                    >释放
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="意向客户" name="yxkh">
            <el-table
              :data="tableData"
              border
              class="table"
              ref="multipleTable"
              header-cell-class-name="table-header"
              @selection-change="handleSelectionChange"
              @row-dblclick="dblclick"
            >
              <el-table-column
                type="selection"
                width="55"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="userRealname"
                label="归属人"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="activePhone"
                label="手机号码"
                align="center"
                v-if="(!hideNum && id != 5051) || roleName == '企业'"
              ></el-table-column>
              <el-table-column
                prop="phone"
                label="手机号码"
                align="center"
                v-if="(hideNum || id == 5051) && roleName != '企业'"
              ></el-table-column>
              <el-table-column
                prop="company"
                label="企业名称"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="customerPhone"
                label="姓名"
                align="center"
              ></el-table-column>

              <el-table-column
                prop="industry"
                label="行业"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="address"
                label="地址"
                align="center"
              ></el-table-column>
              <el-table-column prop="status" label="状态" align="center">
                <template #default="scope">
                  <span v-if="scope.row.status == 0">一般客户</span>
                  <span v-if="scope.row.status == 1">意向客户</span>
                  <span v-if="scope.row.status == 2">无意向客户</span>
                  <span v-if="scope.row.status == 3">成交客户</span>
                  <span v-if="scope.row.status == 4">黑名单</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="createTime"
                label="导入时间"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="changeTime"
                label="修改时间"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="remarks"
                label="备注"
                align="center"
                class="hide"
              ></el-table-column>
              <el-table-column
                prop="choosePeople"
                label="决策人"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="customerScale"
                label="公司规模"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="customerSource"
                label="客户来源"
                align="center"
              ></el-table-column>
              <el-table-column
                label="操作"
                width="280"
                align="center"
                fixed="right"
              >
                <template #default="scope">
                  <el-button
                    type="success"
                    @click="call(scope)"
                    v-if="roleName != '企业'"
                    size="small"
                    >拨打
                  </el-button>
                  <el-button
                    type="primary"
                    @click="addBtn(scope)"
                    v-if="roleName != '企业'"
                    size="small"
                    >短信提醒跟进
                  </el-button>
                  <!-- 释放号码 -->
                  <el-button
                    type="danger"
                    @click="removeButton(scope.row, scope.$index)"
                    size="small"
                    >释放
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="无意向客户" name="wyxkh">
            <el-table
              :data="tableData"
              border
              class="table"
              ref="multipleTable"
              header-cell-class-name="table-header"
              @selection-change="handleSelectionChange"
              @row-dblclick="dblclick"
            >
              <el-table-column
                type="selection"
                width="55"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="userRealname"
                label="归属人"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="activePhone"
                label="手机号码"
                align="center"
                v-if="(!hideNum && id != 5051) || roleName == '企业'"
              ></el-table-column>
              <el-table-column
                prop="phone"
                label="手机号码"
                align="center"
                v-if="(hideNum || id == 5051) && roleName != '企业'"
              ></el-table-column>
              <el-table-column
                prop="company"
                label="企业名称"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="customerPhone"
                label="姓名"
                align="center"
              ></el-table-column>

              <el-table-column
                prop="industry"
                label="行业"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="address"
                label="地址"
                align="center"
              ></el-table-column>
              <el-table-column prop="status" label="状态" align="center">
                <template #default="scope">
                  <span v-if="scope.row.status == 0">一般客户</span>
                  <span v-if="scope.row.status == 1">意向客户</span>
                  <span v-if="scope.row.status == 2">无意向客户</span>
                  <span v-if="scope.row.status == 3">成交客户</span>
                  <span v-if="scope.row.status == 4">黑名单</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="createTime"
                label="导入时间"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="changeTime"
                label="修改时间"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="remarks"
                label="备注"
                align="center"
                class="hide"
              ></el-table-column>
              <el-table-column
                prop="choosePeople"
                label="决策人"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="customerScale"
                label="公司规模"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="customerSource"
                label="客户来源"
                align="center"
              ></el-table-column>
              <el-table-column
                label="操作"
                width="280"
                align="center"
                fixed="right"
              >
                <template #default="scope">
                  <el-button
                    type="success"
                    @click="call(scope)"
                    v-if="roleName != '企业'"
                    size="small"
                    >拨打
                  </el-button>
                  <el-button
                    type="primary"
                    @click="addBtn(scope)"
                    v-if="roleName != '企业'"
                    size="small"
                    >短信提醒跟进
                  </el-button>
                  <!-- 释放号码 -->
                  <el-button
                    type="danger"
                    @click="removeButton(scope.row, scope.$index)"
                    size="small"
                    >释放
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="成交客户" name="cjkh">
            <el-table
              :data="tableData"
              border
              class="table"
              ref="multipleTable"
              header-cell-class-name="table-header"
              @selection-change="handleSelectionChange"
              @row-dblclick="dblclick"
            >
              <el-table-column
                type="selection"
                width="55"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="userRealname"
                label="归属人"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="activePhone"
                label="手机号码"
                align="center"
                v-if="(!hideNum && id != 5051) || roleName == '企业'"
              ></el-table-column>
              <el-table-column
                prop="phone"
                label="手机号码"
                align="center"
                v-if="(hideNum || id == 5051) && roleName != '企业'"
              ></el-table-column>
              <el-table-column
                prop="company"
                label="企业名称"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="customerPhone"
                label="姓名"
                align="center"
              ></el-table-column>

              <el-table-column
                prop="industry"
                label="行业"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="address"
                label="地址"
                align="center"
              ></el-table-column>
              <el-table-column prop="status" label="状态" align="center">
                <template #default="scope">
                  <span v-if="scope.row.status == 0">一般客户</span>
                  <span v-if="scope.row.status == 1">意向客户</span>
                  <span v-if="scope.row.status == 2">无意向客户</span>
                  <span v-if="scope.row.status == 3">成交客户</span>
                  <span v-if="scope.row.status == 4">黑名单</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="createTime"
                label="导入时间"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="changeTime"
                label="修改时间"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="remarks"
                label="备注"
                align="center"
                class="hide"
              ></el-table-column>
              <el-table-column
                prop="choosePeople"
                label="决策人"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="customerScale"
                label="公司规模"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="customerSource"
                label="客户来源"
                align="center"
              ></el-table-column>
              <el-table-column
                label="操作"
                width="280"
                align="center"
                fixed="right"
              >
                <template #default="scope">
                  <el-button
                    type="success"
                    @click="call(scope)"
                    v-if="roleName != '企业'"
                    size="small"
                    >拨打
                  </el-button>
                  <el-button
                    type="primary"
                    @click="addBtn(scope)"
                    v-if="roleName != '企业'"
                    size="small"
                    >短信提醒跟进
                  </el-button>
                  <!-- 释放号码 -->
                  <el-button
                    type="danger"
                    @click="removeButton(scope.row, scope.$index)"
                    size="small"
                    >释放
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="黑名单" name="hmd">
            <el-table
              :data="tableData"
              border
              class="table"
              ref="multipleTable"
              header-cell-class-name="table-header"
              @selection-change="handleSelectionChange"
              @row-dblclick="dblclick"
            >
              <el-table-column
                type="selection"
                width="55"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="userRealname"
                label="归属人"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="activePhone"
                label="手机号码"
                align="center"
                v-if="(!hideNum && id != 5051) || roleName == '企业'"
              ></el-table-column>
              <el-table-column
                prop="phone"
                label="手机号码"
                align="center"
                v-if="(hideNum || id == 5051) && roleName != '企业'"
              ></el-table-column>
              <el-table-column
                prop="company"
                label="企业名称"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="customerPhone"
                label="姓名"
                align="center"
              ></el-table-column>

              <el-table-column
                prop="industry"
                label="行业"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="address"
                label="地址"
                align="center"
              ></el-table-column>
              <el-table-column prop="status" label="状态" align="center">
                <template #default="scope">
                  <span v-if="scope.row.status == 0">一般客户</span>
                  <span v-if="scope.row.status == 1">意向客户</span>
                  <span v-if="scope.row.status == 2">无意向客户</span>
                  <span v-if="scope.row.status == 3">成交客户</span>
                  <span v-if="scope.row.status == 4">黑名单</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="createTime"
                label="导入时间"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="changeTime"
                label="修改时间"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="remarks"
                label="备注"
                align="center"
                class="hide"
              ></el-table-column>
              <el-table-column
                prop="choosePeople"
                label="决策人"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="customerScale"
                label="公司规模"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="customerSource"
                label="客户来源"
                align="center"
              ></el-table-column>
              <el-table-column
                label="操作"
                width="280"
                align="center"
                fixed="right"
              >
                <template #default="scope">
                  <el-button
                    type="success"
                    @click="call(scope)"
                    v-if="roleName != '企业'"
                    size="small"
                    >拨打
                  </el-button>
                  <el-button
                    type="primary"
                    @click="addBtn(scope)"
                    v-if="roleName != '企业'"
                    size="small"
                    >短信提醒跟进
                  </el-button>
                  <!-- 释放号码 -->
                  <el-button
                    type="danger"
                    @click="removeButton(scope.row, scope.$index)"
                    size="small"
                    >释放
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>

        <!--分页  -->
        <div class="pagination">
          <el-pagination
            background
            layout="total, prev, pager, next,sizes"
            :current-page="pageIndex"
            :page-size="pageSize"
            :page-sizes="[10, 20, 50, 100, 200, 300]"
            :total="pageTotal"
            @size-change="handleSizeChange"
            @current-change="handlePageChange"
          ></el-pagination>
        </div>
      </main>
    </el-card>
    <!-- 短信提醒跟进弹出框 -->
    <el-dialog title="短信提醒跟进" :visible.sync="addVisible" width="30%">
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="提醒时间" prop="sendTime">
          <el-date-picker
            v-model="form.sendTime"
            type="datetime"
            placeholder="选择提醒时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            type="textarea"
            :rows="2"
            v-model="form.keqing_remarks"
            placeholder="请输入备注"
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="addVisible = false"
            >取 消</el-button
          >
          <el-button type="primary" @click="submitaddValueS()">确定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 释放弹出框 -->
    <el-dialog title="释放" :visible.sync="removeVisible" width="30%">
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="释放位置1">
          <el-select v-model="removeValue" @change="oneSlt">
            <el-option
              v-for="item in options2"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="removeVisible = false"
            >取 消</el-button
          >
          <el-button type="primary" @click="confirm()" v-if="removeValue == 1"
            >确 定</el-button
          >
          <el-button
            type="primary"
            @click="depDetails"
            v-else-if="removeValue == 0"
            :disabled="disabled1"
            >下一步</el-button
          >
        </span>
      </template>
    </el-dialog>

    <!-- 部门选择弹出框 -->
    <el-dialog title="释放" :visible.sync="depVisible" width="30%">
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="释放位置2">
          <el-select
            v-model="removeParams.departId"
            placeholder="请选择释放位置3"
            filterable
          >
            <el-option
              v-for="item in freeDepart"
              :key="item.id"
              :label="item.departName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            type="primary"
            @click="(removeVisible = true), (depVisible = false)"
            >上一步</el-button
          >
          <el-button type="primary" @click="step">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 批量释放弹出框 -->
    <el-dialog title="批量释放" :visible.sync="batchRemoveVisible" width="30%">
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="释放位置4">
          <el-select v-model="batchRemoveValue" @change="oneSltBatch">
            <el-option
              v-for="item in options2"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="batchRemoveVisible = false"
            >取 消</el-button
          >
          <el-button
            type="primary"
            @click="confirm1()"
            v-if="batchRemoveValue == 1"
            >确 定</el-button
          >
          <el-button
            type="primary"
            @click="depDetails1"
            v-else-if="batchRemoveValue == 0"
            :disabled="disabled2"
            >下一步</el-button
          >
        </span>
      </template>
    </el-dialog>
    <!-- 批量部门选择弹出框 -->
    <el-dialog title="批量释放" :visible.sync="depVisibleBatch" width="30%">
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="释放位置5">
          <el-select
            v-model="departId"
            placeholder="请选择释放位置6"
            filterable
          >
            <el-option
              v-for="item in freeDepart"
              :key="item.id"
              :label="item.departName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            type="primary"
            @click="(batchRemoveVisible = true), (depVisibleBatch = false)"
            >上一步</el-button
          >
          <el-button type="primary" @click="step1()">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 双击弹出框 -->
    <el-dialog :visible.sync="wrapVisible" class="dblclick">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="编辑" name="update">
          <el-form ref="form" :model="form" label-width="100px" class="update">
            <el-form-item label="客户号码">
              <el-input
                v-model="form.phone"
                placeholder="请输入客户号码"
                v-if="(hideNum || id == 5051) && roleName != '企业'"
              ></el-input>
              <el-input
                v-model="form.activePhone"
                placeholder="请输入客户号码"
                v-if="(!hideNum && id != 5051) || roleName == '企业'"
              ></el-input>
            </el-form-item>
            <el-form-item label="客户姓名">
              <el-input
                v-model="form.customerPhone"
                placeholder="请输入客户姓名"
              ></el-input>
            </el-form-item>
            <el-form-item label="公司名称">
              <el-input
                v-model="form.company"
                placeholder="请输入公司名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="决策人">
              <el-input
                v-model="form.choosePeople"
                placeholder="请输入决策人"
              ></el-input>
            </el-form-item>
            <el-form-item label="公司规模">
              <el-input
                v-model="form.customerScale"
                placeholder="请输入公司规模"
              ></el-input>
            </el-form-item>
            <el-form-item label="客户来源">
              <el-input
                v-model="form.customerSource"
                placeholder="请输入客户来源"
              ></el-input>
            </el-form-item>
            <el-form-item label="行业">
              <el-input
                v-model="form.industry"
                placeholder="请输入行业"
              ></el-input>
            </el-form-item>
            <el-form-item label="地址">
              <el-input
                v-model="form.address"
                placeholder="请输入地址"
              ></el-input>
            </el-form-item>
            <el-form-item label="状态">
              <el-radio-group v-model="update_status" style="line-height: 30px">
                <el-radio :label="0">一般客户</el-radio>
                <el-radio :label="1">意向客户</el-radio>
                <el-radio :label="2">无意向客户</el-radio>
                <el-radio :label="3">成交客户</el-radio>
                <el-radio :label="4">黑名单</el-radio>
                <el-radio :label="5">无状态</el-radio>
                <el-radio :label="6">未接通</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="跟进记录">
              <el-input
                type="textarea"
                v-model="form.historyRecord"
                placeholder="请输入跟进记录(备注)"
              ></el-input>
            </el-form-item>
          </el-form>
          <el-button type="primary" @click="updateSubmit" style="width: 100%"
            >确 定
          </el-button>
        </el-tab-pane>
        <el-tab-pane label="跟进记录" name="trailLog">
          <el-row v-show="historys">
            <el-col :span="24">
              <el-table :data="upRecords" style="width: 100%">
                <el-table-column prop="data" label="日期" width="">
                </el-table-column>
                <el-table-column prop="content" label="内容" width="">
                </el-table-column>
              </el-table>
            </el-col>
          </el-row>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>

    <!-- 添加号码弹出框 -->
    <el-dialog title="添加号码" :visible.sync="addOneVisible">
      <el-form ref="form" :model="form" label-width="100px" class="update">
        <el-form-item label="客户号码">
          <el-input
            v-model="form.activePhone"
            placeholder="请输入客户号码"
          ></el-input>
        </el-form-item>
        <el-form-item label="客户姓名">
          <el-input
            v-model="form.customerPhone"
            placeholder="请输入客户姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="公司名称" v-if="realname == 'admin'">
          <el-input
            v-model="form.company"
            placeholder="输入公司名称分配至公司公海"
          ></el-input>
        </el-form-item>
        <el-form-item label="部门">
          <el-input
            v-model="form.departName"
            placeholder="输入部门名称分配至部门海"
          ></el-input>
        </el-form-item>
        <el-form-item label="决策人">
          <el-input
            v-model="form.choosePeople"
            placeholder="请输入决策人"
          ></el-input>
        </el-form-item>
        <el-form-item label="公司规模">
          <el-input
            v-model="form.customerScale"
            placeholder="请输入公司规模"
          ></el-input>
        </el-form-item>
        <el-form-item label="客户来源">
          <el-input
            v-model="form.customerSource"
            placeholder="请输入客户来源"
          ></el-input>
        </el-form-item>
        <el-form-item label="行业">
          <el-input v-model="form.industry" placeholder="请输入行业"></el-input>
        </el-form-item>
        <el-form-item label="地址">
          <el-input v-model="form.address" placeholder="请输入地址"></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-radio-group v-model="update_status" style="line-height: 30px">
            <el-radio :label="0">一般客户</el-radio>
            <el-radio :label="1">意向客户</el-radio>
            <el-radio :label="2">无意向客户</el-radio>
            <el-radio :label="3">成交客户</el-radio>
            <el-radio :label="4">黑名单</el-radio>
            <el-radio :label="5">无状态</el-radio>
            <el-radio :label="6">未接通</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="form.remarks" placeholder="请输入备注"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="addOneVisible = false"
            >取 消</el-button
          >
          <el-button type="primary" @click="addOneSubmit">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 批量导入号码弹出框 -->
    <el-dialog title="批量导入号码" :visible.sync="numShow" width="30%">
      <div class="content">
        <el-button
          type="success"
          icon="el-icon-download"
          @click="upload()"
          class="uploadBtn"
          >点击下载模板
        </el-button>
        <el-upload
          class="upload-demo"
          action=""
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :before-remove="beforeRemove"
          :auto-upload="false"
          multiple
          :limit="1"
          :on-exceed="handleExceed"
          :file-list="fileList"
          :on-change="changeUpload"
          drag="true"
          width="100%"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="numShow = false">取 消</el-button>
          <el-button @click="numsSubmit" :disabled="disabled" type="primary"
            >确 定</el-button
          >
        </span>
      </template>
    </el-dialog>

    <!-- 批量导入号码弹出框 -->
    <el-dialog
      title="全局查询客户"
      :visible.sync="allNumShow"
      width="70%"
      class="num-upload"
    >
      <div>
        <div class="allPhoneQuery">
          <div style="display: flex; flex: 4">
            <span style="width: 80px; line-height: 40px">查询号码</span>
            <el-input
              v-model="allPhoneActivePhone"
              placeholder="请输入完整号码"
              size="medium"
            ></el-input>
          </div>
          <!-- <el-button @click="numShow = false">取 消</el-button> -->
          <el-button
            @click="allPhoneHandleSearch"
            type="primary"
            style="flex: 1"
            >查 询</el-button
          >
        </div>
        <div>
          <!-- <div style="width: 100%;"> -->
          <el-table
            :data="allPhoneTableData"
            border
            class="table"
            ref="multipleTable"
            header-cell-class-name="table-header"
          >
            <el-table-column
              width="100"
              prop="userRealname"
              label="归属人"
              align="center"
            ></el-table-column>
            <el-table-column
              width="100"
              prop="activePhone"
              label="手机号码"
              align="center"
              v-if="(!hideNum && id != 5051) || roleName == '企业'"
            ></el-table-column>
            <el-table-column
              prop="status"
              label="号码位置"
              align="center"
              width="100"
            >
              <template #default="scope">
                <span v-if="scope.row.userId">个人私海</span>
                <span v-if="!scope.row.userId && scope.row.departId"
                  >部门公海</span
                >
                <span
                  v-if="
                    !scope.row.userId &&
                    !scope.row.departId &&
                    scope.row.recycleBin
                  "
                  >公司公海</span
                >
                <span
                  v-if="
                    !scope.row.userId &&
                    !scope.row.departId &&
                    !scope.row.recycleBin
                  "
                  >回收站</span
                >
              </template>
            </el-table-column>
            <el-table-column
              width="100"
              prop="phone"
              label="手机号码"
              align="center"
              v-if="(hideNum || id == 5051) && roleName != '企业'"
            ></el-table-column>
            <el-table-column
              width="100"
              prop="company"
              label="企业名称"
              align="center"
            ></el-table-column>
            <el-table-column
              width="100"
              prop="customerPhone"
              label="姓名"
              align="center"
            ></el-table-column>

            <el-table-column
              width="100"
              prop="industry"
              label="行业"
              align="center"
            ></el-table-column>
            <el-table-column
              width="100"
              prop="address"
              label="地址"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="status"
              label="状态"
              align="center"
              width="100"
            >
              <template #default="scope">
                <span v-if="scope.row.status == 0">一般客户</span>
                <span v-if="scope.row.status == 1">意向客户</span>
                <span v-if="scope.row.status == 2">无意向客户</span>
                <span v-if="scope.row.status == 3">成交客户</span>
                <span v-if="scope.row.status == 4">黑名单</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="createTime"
              label="导入时间"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="changeTime"
              label="修改时间"
              align="center"
            ></el-table-column>
            <el-table-column
              width="100"
              prop="remarks"
              label="备注"
              align="center"
              class="hide"
            ></el-table-column>
            <el-table-column
              width="100"
              prop="choosePeople"
              label="决策人"
              align="center"
            ></el-table-column>
            <el-table-column
              width="100"
              prop="customerScale"
              label="公司规模"
              align="center"
            ></el-table-column>
            <el-table-column
              width="100"
              prop="customerSource"
              label="客户来源"
              align="center"
            ></el-table-column>
            <!-- <el-table-column
              label="操作"
              width="280"
              align="center"
              fixed="right"
            >
              <template #default="scope">
                <el-button
                  type="success"
                  @click="call(scope)"
                  v-if="roleName != '企业'"
                  size="small"
                  >拨打
                </el-button>
                <el-button
                  type="success"
                  @click="addBtn(scope)"
                  v-if="roleName != '企业'"
                  size="small"
                  >短信提醒跟进
                </el-button>
                <el-button
                  type="danger"
                  @click="removeButton(scope.row, scope.$index)"
                  size="small"
                  >释放
                </el-button>
              </template>
            </el-table-column> -->
          </el-table>
        </div>
      </div>
    </el-dialog>

    <!-- 分配号码弹出框 -->
    <el-dialog title="分配号码" :visible.sync="distributeShow" width="30%">
      <el-switch
        v-model="departUser"
        active-text="选择个人"
        inactive-text="选择部门"
        v-if="roleName != '组长'"
      >
      </el-switch>
      <el-form
        ref="form"
        :model="form"
        label-width="100px"
        style="margin-top: 30px"
      >
        <el-form-item label="部门" v-if="roleName != '组长'">
          <el-select
            v-model="departId"
            placeholder="请选择部门，分配至部门海"
            :disabled="departUser"
            filterable
            v-if="roleName != '组长'"
          >
            <el-option
              v-for="item in underDepart"
              :key="item.id"
              :label="item.departName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="个人">
          <el-select
            v-model="userId"
            filterable
            placeholder="请选择个人"
            :disabled="!departUser"
          >
            <el-option
              v-for="item in underUser"
              :key="item.id"
              :label="item.realname"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="distributeShow = false" style="margin-right: 10px"
          >退 出
        </el-button>
        <span class="dialog-footer">
          <el-button type="primary" @click="distributeSubmit"
            >确认提交</el-button
          >
        </span>
      </template>
    </el-dialog>

    <el-dialog
      title="回拨已拨打"
      :visible.sync="callVisible"
      width="30%"
      center
    >
      <span
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          color: red;
        "
        >请在20秒内用手机拨打:{{ xphone }}</span
      >
    </el-dialog>

    <!-- sip弹出框 -->
    <el-dialog
      title="SIP呼叫"
      :visible.sync="sipCallVisible"
      width="30%"
      :before-close="handleClose"
      @close="hang_up"
    >
      <sipCall
        :callPhoneNum="sipPhone"
        :callPhoneData="callData"
        @showValue="showMethod"
      ></sipCall>
    </el-dialog>

    <!-- 添加产品弹出框 -->
    <el-dialog title="添加产品" :visible.sync="cpVisible" width="70%">
      <productData
        @confirm="handleConfirm"
        v-if="cpVisible"
        @productTableData="getTableData"
      />
    </el-dialog>
  </el-card>
</template>

<script>
import productData from "@/views/product/productData.vue";

import sipCall from "@/views/sip/sipCall.vue";
import clientsCharts from "@/views/charts/clientsCharts.vue";
import { ElMessage } from "@/assets/js/elementui-api";

let Message = new ElMessage();
import { userSmsFindOne } from "@/api/user-sms";
import { reminderAdd } from "@/api/reminder";
import {
  phoneFindOneHistoryPhone,
  phoneFindUser,
  phoneList,
  findOne_all,
  phoneDistribute,
  phoneDelete,
  phoneUpdate,
  phoneImportPhone,
  phoneAdd,
} from "@/api/phone";
import { sysDepartList } from "@/api/sys_depart";
import { callAppBind } from "@/api/call";
import { contractAdd } from "@/api/contract";
import { shangjiContractShangjiAdd } from "@/api/shangjiContract";
// import { userList1 } from "@/api/user";

export default {
  components: { clientsCharts, sipCall, productData },
  data() {
    return {
      allPhoneActivePhone: null,
      allPhoneTableData: [],
      allNumShow: false,
      remarkSearch: null,
      typeList: [
        {
          id: 0,
          title: "外卖/生鲜/商超/电商订单配送",
        },
        {
          id: 1,
          title: "快递/物流派送",
        },
        {
          id: 2,
          title: "家政回访服务",
        },
        {
          id: 3,
          title: "会员关怀/居民服务回访/市场问卷调研/民生保障服务",
        },
        {
          id: 4,
          title: "出行服务",
        },
        {
          id: 5,
          title: "办公协作",
        },
        {
          id: 6,
          title: "政采对接",
        },
        {
          id: 7,
          title: "订单类售后服务",
        },
        {
          id: 8,
          title: "法律服务",
        },
        {
          id: 9,
          title: "广告传媒",
        },
        {
          id: 10,
          title: "生物科技",
        },
        {
          id: 11,
          title: "科研技术类",
        },
        {
          id: 12,
          title: "农林牧渔",
        },
        {
          id: 13,
          title: "包装材料类",
        },
        {
          id: 14,
          title: "服装服饰类",
        },
        {
          id: 15,
          title: "化工制造类/推广",
        },
        {
          id: 16,
          title: "一手交通运输工具类",
        },
        {
          id: 17,
          title: "软/硬件服务类",
        },
        {
          id: 18,
          title: "机械机电设备类",
        },
        {
          id: 19,
          title: "汽车维修/车检/保养等",
        },
        {
          id: 20,
          title: "电商回访类",
        },
        {
          id: 21,
          title: "人力资源回访类",
        },
        {
          id: 22,
          title: "少儿素质教育类",
        },
        {
          id: 23,
          title: "生活摄影",
        },
        {
          id: 24,
          title: "品牌招商加盟",
        },
        {
          id: 25,
          title: "园区招商",
        },
        {
          id: 26,
          title: "网站建设/网站推广",
        },
        {
          id: 27,
          title: "再生资源回收",
        },
        {
          id: 28,
          title: "体育用品",
        },
        {
          id: 29,
          title: "办公文教",
        },
        {
          id: 30,
          title: "日用百货销售",
        },
        {
          id: 31,
          title: "人身保险/车险续保",
        },
        {
          id: 32,
          title: "非房屋租赁服务",
        },
        {
          id: 33,
          title:
            "头部外卖平台/网约车平台/线上商超平台/引擎搜索平台/电商平台/音视频平台/新媒体平台/保险平台自有业务服务",
        },
        {
          id: 34,
          title: "家装建材",
        },
        {
          id: 35,
          title: "财税服务",
        },
        {
          id: 36,
          title: "知识产权",
        },
        {
          id: 37,
          title: "工商代办",
        },
        {
          id: 38,
          title: "平台引流/入驻",
        },
        {
          id: 39,
          title: "成人教育/培训类",
        },
        {
          id: 40,
          title: "房地产",
        },
        {
          id: 41,
          title: "会展/展览",
        },
        {
          id: 42,
          title: "学校招生（直招）",
        },
        {
          id: 43,
          title: "二手车/房推广",
        },
        {
          id: 44,
          title: "商铺推广",
        },
        {
          id: 45,
          title: "旅游服务",
        },
        {
          id: 46,
          title: "酒店民宿",
        },
        {
          id: 47,
          title: "K12教育培训/招生",
        },
        {
          id: 48,
          title: "少儿类兴趣培训",
        },
        {
          id: 49,
          title: "资质代办",
        },
        {
          id: 50,
          title: "钢材招商",
        },
        {
          id: 51,
          title: "企业培训",
        },
        {
          id: 52,
          title: "网站推广/加盟",
        },
        {
          id: 53,
          title: "职称评级",
        },
        {
          id: 54,
          title: "餐饮招商",
        },
      ],
      circuitTypeList: [
        {
          id: 0,
          title: "移动回呼",
        },
        {
          id: 1,
          title: "电信回呼",
        },
        {
          id: 2,
          title: "AXB",
        },
        {
          id: 3,
          title: "SIP",
        },
      ],
      productTypeList: [
        {
          id: 0,
          title: "SAAS",
        },
        {
          id: 1,
          title: "PASS",
        },
        {
          id: 2,
          title: "部署",
        },
      ],
      businessTypeList: [
        {
          id: 0,
          title: "新开户",
        },
        {
          id: 1,
          title: "加坐席",
        },
        {
          id: 2,
          title: "续费",
        },
        {
          id: 3,
          title: "测试",
        },
      ],
      ifParentId: null,
      sjyForm: {
        // contractMoney: 0,
      },

      discountPriceList: [
        {
          id: 0,
          title: "无",
          value: 1,
        },
        {
          id: 1,
          title: "一折",
          value: 0.1,
        },
        {
          id: 2,
          title: "二折",
          value: 0.2,
        },
        {
          id: 3,
          title: "三折",
          value: 0.3,
        },
        {
          id: 4,
          title: "四折",
          value: 0.4,
        },
        {
          id: 5,
          title: "五折",
          value: 0.5,
        },
        {
          id: 6,
          title: "六折",
          value: 0.6,
        },
        {
          id: 7,
          title: "七折",
          value: 0.7,
        },
        {
          id: 8,
          title: "八折",
          value: 0.8,
        },
        {
          id: 9,
          title: "九折",
          value: 0.9,
        },
      ],
      cpVisible: false,
      sellingPrice: 0,
      discountPrice: null,
      num: 0,
      multipleSelection: [],
      cpData: [],
      // clientNameList: [],
      // userList: [],
      contractForm: {
        contractMoney: 0,
      },

      ybkh_status: 0,
      yxkh_status: 0,
      wyxkh_status: 0,
      cjkh_status: 0,
      hmd_status: 0,
      ua: "",
      callData: "", // 拨号后接口数据
      sipCallVisible: false,
      numberList: [
        {
          id: 0,
          total: 0,
          name: "status",
          title: "一般客户",
        },
        {
          id: 1,
          total: 0,
          name: "status1",
          title: "意向客户",
        },
        {
          id: 2,
          total: 0,
          name: "status2",
          title: "无意向客户",
        },
        {
          id: 3,
          total: 0,
          name: "status3",
          title: "成交客户",
        },
        {
          id: 4,
          total: 0,
          name: "status4",
          title: "黑名单",
        },
      ],
      activeData: "ybkh",
      wrapVisible: false,
      activeName: "update",
      update_status: null,
      fileList: [],
      depVisible: false,
      depVisibleBatch: false,
      disabled1: true,
      disabled2: true,
      addVisible: false,
      options3: [
        {
          value: 0,
          label: "a",
        },
        {
          value: 1,
          label: "b",
        },
      ],
      options2: [
        {
          value: 0,
          label: "部门公海",
        },
        {
          value: 1,
          label: "公司公海",
        },
      ],
      textarea: "",
      drawer: false,
      direction: "rtl",
      choosePeople: null,
      customerScale: null,
      customerSource: null,
      createTime: null,
      changeTime: null,
      getTime: null,
      removeIndex: [],
      callNum: null,
      sipPhone: null,
      industry: null,
      address: null,
      id: null,
      id1: null,
      oneNum: 0,
      twoNum: 0,
      activePhone: null,
      company: null,
      value: null,
      disabled: false,
      removeParams: {}, //释放列表时需要的参数
      removeValue: "", // 释放选择框选中的项
      batchRemoveValue: "", // 批量释放选择框选中的项
      removeVisible: false, //释放弹出框
      batchRemoveVisible: false, //批量释放弹出框
      addOneVisible: false, //添加弹出框
      distributeShow: false, //分配弹出框
      departUser: true,
      departId: null,
      userId: null,
      departShow: true,
      upRecords: [],
      form: {
        phone: null,
        keqing_remarks: "",
        historyRecord: null,
        choosePeople: null,
        customerScale: null,
        customerSource: null,
        createTime: null,
        changeTime: null,
        getTime: null,
        sendTime: "",
        activePhone: "",
        customerPhone: "",
        company: null,
        departName: null,
        remarks: "",
        industry: null,
        address: null,
        status: "",
        userId: null,
        departId: "",
        id: "",
        id1: null,
        roleId: "",
      },
      nums: [
        {
          id: 0,
          numName: "部门公海",
        },
        {
          id: 1,
          numName: "公司公海",
        },
      ],
      changeNums: null,
      numsArray: [], //选中号码的ID
      freeDepart: [],
      underDepart: [],
      underUser: [],
      remarks: "", //备注（只传不回显）
      numShow: false, //批量导入弹出框
      realname: null,
      indexArr: [],
      isBtn: false,
      hideNum: false,
      roleName: null,
      ms_username: null,
      xphone: null,
      times: 20,
      callVisible: false,
      onecallVisible: false,
      historyRecord: null,
      historys: true,
      tableData: [], //表格内绑定的数据
      pageTotal: 0, //页数
      pageIndex: 1, //当前在第几页
      pageSize: 10, //前端规定每页显示的数量
      page: 1, //第几页
      size: 10, //每页的数量
    };
  },
  created() {
    // this.clientList();
    // this.fondUserList();
    this.$nextTick(function () {
      let bool = localStorage.getItem("hideNum");
      bool == "false" ? (this.hideNum = false) : (this.hideNum = true);
      // console.log("hideNum参数", this.hideNum);
    });
    this.realname = localStorage.getItem("realname");
    this.ifParentId = localStorage.getItem("parentId");
    this.roleName = localStorage.getItem("roleName");
    this.ms_username = localStorage.getItem("ms_username");
    this.id = localStorage.getItem("id") * 1;
    if (this.roleName == "管理员" || this.roleName == "企业") {
      this.findUser();
    }
    if (this.roleName == "组长") {
      this.findUser1();
    }
    this.getList();
  },
  //切换界面就执行方法
  activated() {
    this.getList();
    this.findUser();
    this.findUser1();
  },
  watch: {
    departUser() {
      this.departId = null;
      this.userId = null;
    },
    tableData: {
      handler(newVal) {
        if (newVal.length > 0) {
          this.$nextTick(() => {
            //newVal是新的表格数据，[8] 8是表格数据要默认选中的索引
            this.indexArr.forEach((item) => {
              this.$refs.multipleTable.toggleRowSelection(newVal[item], true);
            });
          });
        }
      },
      // immediate: true
    },
  },
  inject: ["reload"],
  methods: {
    // 合计的处理
    formatter(row, column) {
      // console.log("合计处理", row);
      let sum = row.sellingPrice * row.discountPrice * row.num;
      row.sumMoney = sum.toFixed(2);
      return sum.toFixed(2);
    },
    // 移除
    del(row) {
      // console.log("this.cpData", this.cpData);
      this.$confirm("是否移除该产品", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          this.cpData.forEach((v, i) => {
            if (v.id == row.id) {
              this.cpData.splice(i, 1);
            }
            Message.success("已移除");
          });
        })
        .catch(() => {
          Message.info("已取消删除");
        });
    },
    // 获取添加的产品信息
    getTableData(val) {
      this.cpData = val.multipleSelection;
      this.cpVisible = val.bool;
      console.log("获取产品信息");
    },
    handleConfirm(v) {
      this.multipleSelection = v;
    },
    // 添加产品
    addCP() {
      this.cpVisible = true;
    },
    // 新增合同
    contractSubmit() {
      let params = {
        bsf: 7,
        // 合同
        contractName: this.contractForm.contractName,
        clientName: this.contractForm.clientName,
        clientId: this.contractForm.clientId,
        companyUser: this.contractForm.companyUser,
        companyUserId: this.contractForm.companyUserId,
        companyName: this.contractForm.companyName,
        orderDate: this.contractForm.orderDate
          ? this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.contractForm.orderDate)
          : null,
        contractMoney: this.contractForm.contractMoney,
        remark: this.contractForm.remark,
      };
      params.contractPriceList = this.cpData.map((v) => {
        return {
          productName: v.productName, // 产品名字
          unit: v.unit, // 产品价格
          sellingPrice: v.sellingPrice, // 售价
          discountPrice: v.discountPrice, // 折扣
          num: v.num, // 数量
          sumMoney: v.sumMoney, // 合计
        };
      });
      let res = contractAdd(params);
      res.then((res) => {
        if (res.data.statusCode == "00000") {
          Message.success(res.data.message);
          this.wrapVisible = false;
          this.getList();
        } else {
          Message.error(res.data.message);
          this.wrapVisible = false;
          this.getList();
        }
      });
    },
    // 新增合同--商机云
    sjySubmit() {
      if (this.sjyForm.installment) {
        if (this.sjyForm.installment == "1") {
          this.sjyInstallment = "是";
        } else {
          this.sjyInstallment = "否";
        }
      }
      let params = {
        bsf: 7,
        // 合同
        companyName: this.sjyForm.companyName,
        type: this.sjyForm.type,
        phone: this.sjyForm.phone,
        telephone: this.sjyForm.telephone,
        businessHandling: this.sjyForm.businessHandling,
        businessType: this.sjyForm.businessType,
        routeType: this.sjyForm.routeType,
        numberFee: this.sjyForm.numberFee,
        numberNum: this.sjyForm.numberNum,
        combo: this.sjyForm.combo,
        seatsNumber: this.sjyForm.seatsNumber,
        rateWithin: this.sjyForm.rateWithin,
        rateExtra: this.sjyForm.rateExtra,
        cooperationTime: this.sjyForm.cooperationTime,
        comboRecharge: this.sjyForm.comboRecharge,
        featureFee: this.sjyForm.featureFee,
        feeTotal: this.sjyForm.feeTotal,
        presented: this.sjyForm.presented,
        installment: this.sjyInstallment,
        remarke: this.sjyForm.remarke,
        clientId: this.sjyForm.clientId,
      };
      // console.log(345678, params);
      let res = shangjiContractShangjiAdd(params);
      res.then((res) => {
        if (res.data.statusCode == "00000") {
          Message.success(res.data.message);
          this.wrapVisible = false;
          this.getList();
        } else {
          Message.error(res.data.message);
          this.wrapVisible = false;
          this.getList();
        }
      });
    },

    // 挂断
    hang_up() {
      if (this.ua) {
        this.ua.stop();
      }
    },
    // sip挂断关闭弹框
    showMethod(obj) {
      this.sipCallVisible = obj.bool;
      this.ua = obj.ua;
    },
    // 编辑关闭回调
    handleClose(done) {
      this.$confirm("如需关闭弹框，请挂断SIP通话")
        .then((_) => {
          // done();
        })
        .catch((_) => {});
    },
    // 双击事件
    dblclick(row) {
      console.log("row", row);
      this.wrapVisible = true;
      this.form = { ...row };
      this.update_status = null;
      this.activeName = "update";
      // contractForm
      this.contractForm.clientName = row.customerPhone;
      this.contractForm.clientId = row.id;
      this.contractForm.companyUser = row.userRealname;
      this.contractForm.companyUserId = row.userId;
      this.contractForm.companyName = row.company;
      // sjyForm
      this.sjyForm = {};
      this.sjyForm.companyName = row.company;
      this.sjyForm.clientId = row.id;
    },
    // 双击切换事件
    handleClick(e) {
      if (e.index == "1") {
        this.record();
      }
    },
    // 分页
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    // 查询是否开通短信服务
    submitaddValue() {
      let parentId = JSON.parse(localStorage.getItem("parentId"));
      let params = { parentId };
      let res = userSmsFindOne(params);
      res.then((res) => {
        if (res.data.data != null) {
          // 可用
          this.addVisible = true;
        } else {
          // 不可用
          Message.success("开通短信服务后可用");
        }
      });
    },
    // 短信提醒跟进
    addBtn(row) {
      // this.addVisible = true;
      // console.log("-----", row.row);
      this.form.userId = row.row.userId;
      this.form.activePhone = row.row.activePhone;
      this.form.customerPhone = row.row.customerPhone;
      this.form.sendTime = row.row.sendTime;
      this.form.keqing_remarks = row.row.keqing_remarks;
      this.submitaddValue();
    },
    // 短信提醒跟进确定
    submitaddValueS() {
      let sendTime = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.form.sendTime);
      let params = {
        activePhone: this.form.activePhone,
        sendTime,
        userId: this.form.userId,
        name: this.form.customerPhone,
        remarks: this.form.keqing_remarks,
      };
      let res = reminderAdd(params);
      res.then((res) => {
        Message.success(res.data.message);
        this.addVisible = false;
        this.getList();
      });
    },
    // 转换日期
    getYMDHMS(format, date) {
      if (!date) {
        return "";
      }
      date = new Date(date);
      const dataItem = {
        "Y+": date.getFullYear().toString(),
        "m+": (date.getMonth() + 1).toString(),
        "d+": date.getDate().toString(),
        "H+": date.getHours().toString(),
        "M+": date.getMinutes().toString(),
        "S+": date.getSeconds().toString(),
      };
      Object.keys(dataItem).forEach((item) => {
        const ret = new RegExp(`(${item})`).exec(format);
        if (ret) {
          format = format.replace(
            ret[1],
            ret[1].length === 1
              ? dataItem[item]
              : dataItem[item].padStart(ret[1].length, "0")
          );
        }
      });
      return format;
    },
    // 关闭历史记录时
    handleCloseRecord() {
      this.drawer = false;
      this.upRecords = [];
    },
    // 根进记录
    record() {
      let params = {
        phoneId: this.form.id,
      };
      let res = phoneFindOneHistoryPhone(params);
      res.then((res) => {
        let data = res.data.data;
        this.upRecords = [];

        if (data.historyRecord == "") {
          data.historyRecord.split("").forEach((v) => {
            this.upRecords.push(this.initArr(v));
          });
        } else {
          data.historyRecord.forEach((v) => {
            this.upRecords.push(this.initArr(v));
          });
        }
        this.drawer = true;
      });
    },
    // 将跟进历史数据格式化
    initArr(str) {
      let obj = {
        data: str.substr(-19),
        content: str.slice(0, -20),
      };
      return obj;
    },
    // 将归属人数据格式化
    initArr1(str) {
      let obj = {
        data: str.substr(-19),
        content: str.slice(0, -22),
      };
      return obj;
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    //选择table表格
    handleSelectionChange(val) {
      this.numsArray = [];
      val.forEach((item) => {
        this.numsArray.push(item.id);
      });
      // console.log(this.numsArray);
    },
    //查询用户下所有员工
    findUser() {
      let params = {};
      let res = phoneFindUser(params);
      res.then((res) => {
        this.underDepart = res.data.data.depart;
        this.underUser = res.data.data.user;
      });
    },
    //释放具体部门
    findUser2() {
      // let gsId = localStorage.getItem("id") * 1;
      let bmId = localStorage.getItem("parentId") * 1;
      let params = {
        topId: "",
      };
      if (this.roleName == "公司") {
        // params.topId = gsId;
      } else {
        params.topId = bmId;
      }
      let res = sysDepartList(params);
      res.then((res) => {
        let data = res.data.data;
        if (!data) {
          Message.error("部门为空");
          this.depVisible = false;
          this.removeVisible = false;
          this.batchRemoveVisible = false;
          this.depVisibleBatch = false;
          return false;
        }
        for (let key in data) {
          this.depId = data[key].id;
          this.freeDepart = data;
        }
      });
    },
    findUser1() {
      let params = {};
      let res = phoneFindUser(params);
      res.then((res) => {
        this.underDepart = res.data.data.depart;
        this.underUser = res.data.data.user;
      });
    },
    // 补0
    fillZero(n) {
      return n <= 9 ? "0" + n : n;
    },
    // 获取年月日的函数
    getYmd(data) {
      let y = data.getFullYear(); //年
      let m = data.getMonth() + 1; //月
      let d = data.getDate(); //日
      return [y, this.fillZero(m), this.fillZero(d)].join("-");
    },
    // 获取时间的函数
    getHms(times) {
      let h = times.getHours(); //时
      let m = times.getMinutes(); //分
      let s = times.getSeconds(); //秒
      return [this.fillZero(h), this.fillZero(m), this.fillZero(s)].join(":");
    },
    //搜索
    handleSearch() {
      // alert(111)
      let obj = {};
      let obj1 = {};
      let obj2 = {};

      // 转换日期
      let sta_createTime = null;
      let end_createTime = null;
      if (this.createTime) {
        sta_createTime =
          this.getYmd(this.createTime[0]) +
          " " +
          this.getHms(this.createTime[0]);
        end_createTime =
          this.getYmd(this.createTime[1]) +
          " " +
          this.getHms(this.createTime[1]);
      }

      let sta_changeTime = null;
      let end_changeTime = null;
      if (this.changeTime) {
        sta_changeTime =
          this.getYmd(this.changeTime[0]) +
          " " +
          this.getHms(this.changeTime[0]);
        end_changeTime =
          this.getYmd(this.changeTime[1]) +
          " " +
          this.getHms(this.changeTime[1]);
      }

      let sta_getTime = null;
      let end_getTime = null;
      if (this.getTime) {
        sta_getTime =
          this.getYmd(this.getTime[0]) + " " + this.getHms(this.getTime[0]);
        end_getTime =
          this.getYmd(this.getTime[1]) + " " + this.getHms(this.getTime[1]);
      }

      let params = {};
      this.page = 1;
      // let status = 1;
      // console.log(123456789, this.cutIndex);
      if (!this.cutIndex) {
        this.cutIndex = 0;
      }
      if (this.roleName == "管理员" || this.roleName == "企业") {
        params = {
          bsf: 1,
          status: this.cutIndex,
          page: this.page,
          size: this.size,
          activePhone: this.activePhone,
          userId: this.userId,
          depart_id: this.departId,
          company: this.company,
          sta_createTime: sta_createTime,
          end_createTime: end_createTime,
          sta_changeTime: sta_changeTime,
          end_changeTime: end_changeTime,
          sta_getTime: sta_getTime,
          end_getTime: end_getTime,
          industry: this.industry,
          address: this.address,
          // choosePeople: this.choosePeople,
          // customerScale: this.customerScale,
          customerSource: this.customerSource,
        };
      } else {
        params = {
          bsf: 1,
          status: this.cutIndex,
          page: this.page,
          size: this.size,
          userId: this.id1,
          industry: this.industry,
          address: this.address,
          activePhone: this.activePhone,
          company: this.company,
          sta_createTime: sta_createTime,
          end_createTime: end_createTime,
          sta_changeTime: sta_changeTime,
          end_changeTime: end_changeTime,
          sta_getTime: sta_getTime,
          end_getTime: end_getTime,
          // choosePeople: this.choosePeople,
          // customerScale: this.customerScale,
          customerSource: this.customerSource,
        };
      }

      if (this.remarkSearch) {
        params.remarks = this.remarkSearch;
      }

      // console.log(123456789, params);
      let res = phoneList(params);
      let res1 = phoneFindUser;
      res.then((a) => {
        this.tableData = a.data.data;
        this.pageTotal = a.data.total;
        a.data.data.forEach((item) => {
          let str = item.activePhone;
          item.phone = str.substring(0, 3) + "****" + str.substring(7);
        });
      });
      res1.then((b) => {
        this.underDepart = b.data.data.depart;
        this.underUser = b.data.data.user;
      });
    },
    //重置
    reset() {
      this.reload();
    },
    // 全局查找
    allHandleSearch() {
      this.allNumShow = true;
    },

    //全局搜索
    async allPhoneHandleSearch() {
      // allPhoneActivePhone
      let params = {
        activePhone: this.allPhoneActivePhone,
      };
      let res = await findOne_all(params);
      this.allPhoneTableData = [];
      this.allPhoneTableData.push(res.data.data);
    },
    //分配号码
    distributeNums() {
      this.findUser();
      this.departId = null;
      this.userId = null;
      this.distributeShow = true;
    },
    //分配号码确认提交
    distributeSubmit() {
      if (this.userId == "" || this.userId == null) {
        let params = {
          listId: this.numsArray,
          departId: this.departId,
        };
        let res = phoneDistribute(params);
        res.then((res) => {
          if (res.data.statusCode == "00000") {
            Message.success(res.data.message);
          } else {
            Message.error(res.data.message);
          }
          this.distributeShow = false;
          this.getList();
        });
      } else {
        let params = {
          listId: this.numsArray,
          userId: this.userId,
        };
        let res = phoneDistribute(params);
        res.then((res) => {
          if (res.data.statusCode == "00000") {
            Message.success(res.data.message);
            this.numsArray = [];
          } else {
            Message.error(res.data.message);
            this.numsArray = [];
          }
          this.distributeShow = false;
          this.getList();
        });
      }
    },
    // 展示
    getList() {
      let params = {};
      if (this.cutIndex) {
        this.cutStatus = this.cutIndex;
      } else if (!this.cutIndex) {
        this.cutStatus = 0;
      }
      if (this.roleName == "管理员" || this.roleName == "企业") {
        params = {
          bsf: 1,
          page: this.page,
          size: this.size,
          status: this.cutStatus,
          activePhone: this.activePhone,
          userId: this.userId,
          depart_id: this.departId,
          company: this.company,
          createTime: this.createTime,
          changeTime: this.changeTime,
          getTime: this.getTime,
          choosePeople: this.choosePeople,
          customerScale: this.customerScale,
          industry: this.industry,
          address: this.address,
          customerSource: this.customerSource,
        };
      } else {
        params = {
          bsf: 1,
          page: this.page,
          size: this.size,
          industry: this.industry,
          address: this.address,
          userId: this.id1,
          status: this.cutStatus,
          activePhone: this.activePhone,
          company: this.company,
          createTime: this.createTime,
          changeTime: this.changeTime,
          getTime: this.getTime,
          choosePeople: this.choosePeople,
          customerScale: this.customerScale,
          customerSource: this.customerSource,
        };
      }
      let res = phoneList(params);
      res.then((res) => {
        // 客户分类总数
        this.ybkh_status = res.data.status;
        this.yxkh_status = res.data.status1;
        this.wyxkh_status = res.data.status2;
        this.cjkh_status = res.data.status3;
        this.hmd_status = res.data.status4;
        this.tableData = res.data.data;
        this.pageTotal = res.data.total;
        res.data.data.forEach((item) => {
          let str = item.activePhone;
          item.phone = str.substring(0, 3) + "****" + str.substring(7);
        });
      });
    },
    // 展示切换
    clickData(e) {
      this.cutIndex = e.index;
      let params = {};
      if (this.cutIndex == "0") {
        if (this.roleName == "管理员" || this.roleName == "企业") {
          params = {
            bsf: 1,
            page: this.page,
            size: this.size,
            status: 0,
            activePhone: this.activePhone,
            userId: this.userId,
            depart_id: this.departId,
            company: this.company,
            createTime: this.createTime,
            changeTime: this.changeTime,
            getTime: this.getTime,
            choosePeople: this.choosePeople,
            customerScale: this.customerScale,
            industry: this.industry,
            address: this.address,
            customerSource: this.customerSource,
          };
        } else {
          params = {
            bsf: 1,
            page: this.page,
            size: this.size,
            industry: this.industry,
            address: this.address,
            userId: this.id1,
            status: 0,
            activePhone: this.activePhone,
            company: this.company,
            createTime: this.createTime,
            changeTime: this.changeTime,
            getTime: this.getTime,
            choosePeople: this.choosePeople,
            customerScale: this.customerScale,
            customerSource: this.customerSource,
          };
        }
      } else if (this.cutIndex == "1") {
        if (this.roleName == "管理员" || this.roleName == "企业") {
          params = {
            bsf: 1,
            page: this.page,
            size: this.size,
            status: 1,
            activePhone: this.activePhone,
            userId: this.userId,
            depart_id: this.departId,
            company: this.company,
            createTime: this.createTime,
            changeTime: this.changeTime,
            getTime: this.getTime,
            choosePeople: this.choosePeople,
            customerScale: this.customerScale,
            industry: this.industry,
            address: this.address,
            customerSource: this.customerSource,
          };
        } else {
          params = {
            bsf: 1,
            page: this.page,
            size: this.size,
            industry: this.industry,
            address: this.address,
            userId: this.id1,
            status: 1,
            activePhone: this.activePhone,
            company: this.company,
            createTime: this.createTime,
            changeTime: this.changeTime,
            getTime: this.getTime,
            choosePeople: this.choosePeople,
            customerScale: this.customerScale,
            customerSource: this.customerSource,
          };
        }
      } else if (this.cutIndex == "2") {
        if (this.roleName == "管理员" || this.roleName == "企业") {
          params = {
            bsf: 1,
            page: this.page,
            size: this.size,
            status: 2,
            activePhone: this.activePhone,
            userId: this.userId,
            depart_id: this.departId,
            company: this.company,
            createTime: this.createTime,
            changeTime: this.changeTime,
            getTime: this.getTime,
            choosePeople: this.choosePeople,
            customerScale: this.customerScale,
            industry: this.industry,
            address: this.address,
            customerSource: this.customerSource,
          };
        } else {
          params = {
            bsf: 1,
            page: this.page,
            size: this.size,
            industry: this.industry,
            address: this.address,
            userId: this.id1,
            status: 2,
            activePhone: this.activePhone,
            company: this.company,
            createTime: this.createTime,
            changeTime: this.changeTime,
            getTime: this.getTime,
            choosePeople: this.choosePeople,
            customerScale: this.customerScale,
            customerSource: this.customerSource,
          };
        }
      } else if (this.cutIndex == "3") {
        if (this.roleName == "管理员" || this.roleName == "企业") {
          params = {
            bsf: 1,
            page: this.page,
            size: this.size,
            status: 3,
            activePhone: this.activePhone,
            userId: this.userId,
            depart_id: this.departId,
            company: this.company,
            createTime: this.createTime,
            changeTime: this.changeTime,
            getTime: this.getTime,
            choosePeople: this.choosePeople,
            customerScale: this.customerScale,
            industry: this.industry,
            address: this.address,
            customerSource: this.customerSource,
          };
        } else {
          params = {
            bsf: 1,
            page: this.page,
            size: this.size,
            industry: this.industry,
            address: this.address,
            userId: this.id1,
            status: 3,
            activePhone: this.activePhone,
            company: this.company,
            createTime: this.createTime,
            changeTime: this.changeTime,
            getTime: this.getTime,
            choosePeople: this.choosePeople,
            customerScale: this.customerScale,
            customerSource: this.customerSource,
          };
        }
      } else if (this.cutIndex == "4") {
        if (this.roleName == "管理员" || this.roleName == "企业") {
          params = {
            bsf: 1,
            page: this.page,
            size: this.size,
            status: 4,
            activePhone: this.activePhone,
            userId: this.userId,
            depart_id: this.departId,
            company: this.company,
            createTime: this.createTime,
            changeTime: this.changeTime,
            getTime: this.getTime,
            choosePeople: this.choosePeople,
            customerScale: this.customerScale,
            industry: this.industry,
            address: this.address,
            customerSource: this.customerSource,
          };
        } else {
          params = {
            bsf: 1,
            page: this.page,
            size: this.size,
            industry: this.industry,
            address: this.address,
            userId: this.id1,
            status: 4,
            activePhone: this.activePhone,
            company: this.company,
            createTime: this.createTime,
            changeTime: this.changeTime,
            getTime: this.getTime,
            choosePeople: this.choosePeople,
            customerScale: this.customerScale,
            customerSource: this.customerSource,
          };
        }
      }
      let res = phoneList(params);
      res.then((res) => {
        this.tableData = res.data.data;
        this.pageTotal = res.data.total;
        res.data.data.forEach((item) => {
          let str = item.activePhone;
          item.phone = str.substring(0, 3) + "****" + str.substring(7);
        });
      });
    },
    //拨打按钮
    call(row) {
      let parentId = JSON.parse(localStorage.getItem("parentId"));
      this.sipPhone = row.row.activePhone;
      let params = {
        passivePhone: this.sipPhone,
        activePhone: this.ms_username,
        parentId,
      };
      let res = callAppBind(params);
      res.then((res) => {
        this.callData = res;
        console.log("sip接口调用返回信息", res);
        if (res.data.statusCode == "00000") {
          Message.success(res.data);
          this.wrapVisible = true;
          this.form = { ...row.row };
          this.update_status = null;
          this.activeName = "update";
          // contractForm
          this.contractForm.clientName = row.row.customerPhone;
          this.contractForm.clientId = row.row.id;
          this.contractForm.companyUser = row.row.userRealname;
          this.contractForm.companyUserId = row.row.userId;
          this.contractForm.companyName = row.row.company;
          // sjyForm
          this.sjyForm = {};
          this.sjyForm.companyName = row.row.company;
          this.sjyForm.clientId = row.row.id;

          //判断是否是AXB呼叫模式
          if (res.data.data.sip) {
            // console.log("sipsip");
            this.sipCallVisible = true;
            // this.callNum;
          } else if (res.data != null && !res.data.data.sip) {
            console.log(res.data.data);
            this.xphone = res.data.data;
            this.callVisible = true;
            this.countTime();
            this.update(row);
          } else {
            Message.success("请注意接听手机电话！");
          }
        } else {
          let data = res.data.data;
          if (data) {
            Message.error(JSON.parse(data).message);
          } else {
            Message.error(res.data.message);
          }
        }
      });
    },
    //axb呼叫模式弹窗
    countTime() {
      if (this.times > 0) {
        setTimeout(() => {
          this.times -= 1;
          this.countTime();
        }, 1000);
      } else {
        this.callVisible = false;
      }
    },
    //编辑按钮
    update(row) {
      this.wrapVisible = true;
      this.form = row.row;
      this.update_status = null;
    },
    // 释放按钮
    removeButton(data, index) {
      if (data.status == 3) {
        Message.warning("此号码为成交客户，无法释放");
        return;
      } else if (data.status == 4) {
        Message.warning("此号码为黑名单，无法释放");
        return;
      }
      this.removeIndex = index;
      let params = {
        listId: [data.id],
        roleId: data.roleId,
        departId: data.departId,
      };
      this.removeParams = params;
      this.removeVisible = true;
    },
    // 下拉事件
    oneSlt() {
      if (this.removeValue == 0) {
        this.disabled1 = false;
      } else {
      }
    },
    // 批量下拉事件
    oneSltBatch() {
      if (this.batchRemoveValue == 0) {
        this.disabled2 = false;
      } else {
      }
    },
    // 释放 公司
    confirm() {
      // 没有部门id，就去公司公海
      this.removeParams.departId = "";
      let res = phoneDelete(this.removeParams);
      res.then((res) => {
        Message.success("释放成功");
        this.tableData.splice(this.removeIndex, 1);
        this.removeVisible = false;
        this.getList();
      });
    },
    // 释放 部门
    step() {
      let res = phoneDelete(this.removeParams);
      res.then((res) => {
        Message.success("释放成功");
        this.tableData.splice(this.removeIndex, 1);
        this.getList();
      });
    },
    // 释放确认事件
    submitRemoveValue() {
      if (this.removeValue === "") return;
      // console.log(this.removeParams.departId);
      // 有部门id，就去部门
      if (this.removeValue === 0) {
        this.step();
      } else if (this.removeValue === 1) {
        this.confirm();
      } else {
      }
    },
    // 查询具体部门
    depDetails() {
      this.depVisible = true;
      this.removeVisible = false;
      this.findUser2();
    },
    // 批量查询具体部门
    depDetails1() {
      this.batchRemoveVisible = false;
      this.depVisibleBatch = true;
      this.findUser2();
    },
    // 批量释放按钮
    moreDelete() {
      if (this.numsArray.length == 0) {
        Message.warning("未选中号码");
        return;
      }
      this.batchRemoveVisible = true;
    },
    // 释放 公司
    confirm1() {
      let params = {
        listId: this.numsArray,
        roleId: this.tableData[0].roleId,
        departId: "",
      };
      let res = phoneDelete(params);
      res.then((res) => {
        Message.success("释放成功");
        this.numsArray.map((v, i) => {
          let index = this.tableData.findIndex((item) => item.id === v);
          this.tableData.splice(index, 1);
          // this.numsArray.splice(i, 1);
        });
        this.getList();
      });
    },
    // 释放 部门
    step1() {
      let params = {
        listId: this.numsArray,
        roleId: this.tableData[0].roleId,
        departId: this.departId,
      };
      let res = phoneDelete(params);
      res.then((res) => {
        Message.success("释放成功");
        this.numsArray.map((v, i) => {
          let index = this.tableData.findIndex((item) => item.id === v);
          this.tableData.splice(index, 1);
          // this.numsArray.splice(i, 1);
        });
        this.getList();
      });
    },
    // 批量释放确认事件
    submitBatchRemoveValue() {
      this.numsArray.map((v, i) => {
        this.tableData.findIndex((item) => {
          if (item.status == 3 || item.status == 4) {
            Message.warning("选中列表中包含成交客户或黑名单，无法释放");
            return;
          }
        });
      });
      if (this.batchRemoveValue === "") return;
      if (this.batchRemoveValue === 0) {
        this.step1();
      } else if (this.batchRemoveValue === 1) {
        this.confirm1();
      } else {
      }
      setTimeout(function () {
        location.reload();
      }, 1000);
    },
    //编辑提交
    updateSubmit() {
      if (this.update_status == null) {
        Message.warning("请选择状态!!!");
      } else {
        let params = {
          historyRecord: this.form.historyRecord,
          activePhone: this.form.activePhone,
          customerPhone: this.form.customerPhone,
          company: this.form.company,
          remarks: this.form.remarks,
          status: this.update_status,
          userId: this.form.userId,
          departId: this.form.departId,
          id: this.form.id,
          roleId: this.form.roleId,
          choosePeople: this.form.choosePeople,
          customerScale: this.form.customerScale,
          customerSource: this.form.customerSource,
          industry: this.form.industry,
          address: this.form.address,
        };
        let res = phoneUpdate(params);
        res.then((res) => {
          if (res.data.statusCode == "00000") {
            Message.success(res.data.message);
            this.wrapVisible = false;
            this.getList();
          } else {
            Message.error(res.data.message);
            this.wrapVisible = false;
            this.getList();
          }
        });
      }
    },
    //下载模板
    upload() {
      // window.open("http://113.250.189.239:9005/phone.xls");
      window.open("http://113.250.189.239:9005/phone.xls");
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      Message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定释放 ${file.name}？`);
    },
    changeUpload(file, fileList) {
      this.file = file.raw;
    },
    //批量导入按钮
    allNums() {
      this.disabled = false;
      this.numShow = true;
      this.formDate = null;
    },
    //批量导入提交
    numsSubmit() {
      this.disabled = true;
      this.numShow = false;
      let formDate = new FormData();
      Message.success("数据加载中，稍等！请勿离开页面");
      formDate.append("file", this.file);
      let res = phoneImportPhone(formDate);
      res.then((res) => {
        if (res.data.statusCode == "000000") {
          Message.success(res.data.message);
          this.file = {};
        } else {
          Message.error(res.data.message);
          this.file = {};
        }
      });
    },
    //添加号码
    addNums() {
      this.form = [];
      this.addOneVisible = true;
    },
    //添加号码确认
    addOneSubmit() {
      let params = {
        activePhone: this.form.activePhone,
        customerPhone: this.form.customerPhone,
        company: this.form.company,
        departName: this.form.departName,
        remarks: this.form.remarks,
        status: this.update_status,
        userId: this.id,
        choosePeople: this.form.choosePeople,
        customerScale: this.form.customerScale,
        customerSource: this.form.customerSource,
        industry: this.form.industry,
        address: this.form.address,
      };
      let res = phoneAdd(params);
      res.then((res) => {
        if (res.data.statusCode == "00000") {
          Message.success(res.data.message);
          this.addOneVisible = false;
        } else {
          Message.error(res.data.message);
          this.addOneVisible = false;
        }
        this.getList();
      });
    },
  },
};
</script>

<style lang="less" scoped>
#clientNums {
  header {
    #clientNumber {
      margin-top: 20px;

      .num-wrap {
        background-color: pink;
        color: #fff;
        text-align: center;
        width: 19%;
        height: 60px;
        line-height: 24px;
        padding: 5px;
        box-sizing: border-box;

        .title {
          font-size: 14px;
        }

        .number {
          font-size: 16px;
        }
      }
    }

    h1 {
      font-weight: normal;
    }

    .search {
      display: flex;
      margin-top: 20px;
      flex-direction: column;

      .left {
        display: flex;
        align-items: center;
        height: 100%;
        flex-wrap: wrap;
        line-height: 30px;

        .left-wrap {
          display: flex;
          align-items: center;

          span {
            margin: 10px;
            width: 30%;
            text-align: right;
            align-items: center;
            font-size: 12px;
          }
        }
      }

      .right {
        margin-top: 20px;
        text-align: right;
        display: flex;
        /* justify-content: space-between; */
        justify-content: flex-end;
        align-items: center;
        height: 100%;
      }
    }
  }

  main {
    .pagination {
      text-align: right;
      margin: 20px 0;
    }
  }

  .uploadBtn {
    width: 68%;
  }

  /deep/ .el-dialog {
    width: 70%;
  }

  .update {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-start;
    align-items: flex-start;

    .el-form-item {
      width: 50%;
    }
  }

  .client-details {
    /deep/ .el-drawer.rtl {
      height: 40%;
      bottom: 0;
      top: auto;

      .update-details {
        width: 70%;
      }

      .data-details {
        width: 300px;

        .title {
          color: #fff;
          background-color: #53a1f0;
          padding: 10px 20px;
          box-sizing: border-box;
          font-size: 18px;
          text-align: center;
        }

        .users {
          font-size: 14px;
          line-height: 30px;
        }
      }
    }
  }

  .dblclick /deep/ .el-dialog__header {
    padding: 0;
  }

  .contract {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto auto auto;
    grid-gap: 10px;
  }
}
</style>