/**
 * contract
 * 合同
 */

import request from '@/utils/request'

// 新增合同
export const contractAdd = (data) => {
    return request({
        method: 'post',
        url: '/contract/add',
        data
    })
}
// 删除合同
export const contractDelete = (data) => {
    return request({
        method: 'post',
        url: '/contract/delete',
        data
    })
}
// 编辑合同
export const contractUpdate = (data) => {
    return request({
        method: 'post',
        url: '/contract/update',
        data
    })
}
// 展示合同
export const contractSelect = (data) => {
    return request({
        method: 'post',
        url: '/contract/select',
        data
    })
}
// 待审批合同
export const contractRemindContract = (data) => {
    return request({
        method: 'post',
        url: '/contract/remindContract',
        data
    })
}
// 已审批合同
export const contractParticipantContract = (data) => {
    return request({
        method: 'post',
        url: '/contract/participantContract',
        data
    })
}
// 审批--查询
export const contractSelectContractWorkOrderType = (data) => {
    return request({
        method: 'post',
        url: '/contract/selectContractWorkOrderType',
        data
    })
}
// 审批--修改
export const contractUpdateContractOrderType = (data) => {
    return request({
        method: 'post',
        url: '/contract/updateContractOrderType',
        data
    })
}
// 查询产品信息
export const contractSelectContractPrice = (data) => {
    return request({
        method: 'post',
        url: '/contract/selectContractPrice',
        data
    })
}
// 
export const contractAppBind = (data) => {
    return request({
        method: 'post',
        url: '/contract/app_bind',
        data
    })
}
